@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter/Inter-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: auto;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter/Inter-Medium.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: auto;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter/Inter-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
  font-display: auto;
}
