/* CSS overrides are still necessary in some cases where ant design doesn't provide variables */
daybreak-blue: #1890ff;

/* Table Header Font */
.ant-table-thead > tr > th.ant-table-cell {
  font-weight: semibold;
}

/* Active Pagination Item */
.ant-pagination-item-active {
  border-color: #6b57f8 !important;
}
.ant-pagination-item-active a {
  color: #6b57f8 !important;
}

/* Menu Item Adjustments */
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: 0px !important;
}
.ant-menu-inline .ant-menu-vertical .ant-menu-item {
  padding: 0 calc(50% - 20px / 2) !important;
  margin-bottom: 0px !important;
}
.ant-menu-vertical .ant-menu-item-selected {
  width: 66px;
  border-right: solid 2px #6b57f8;
  margin-right: -2px;
}

/* Removing the underline from the tabs */
.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0px !important;
}

/* Expand icon on expandable table rows (the + box) */
.ant-table-row-expand-icon, .ant-table-row-expand-icon-collapsed, .ant-table-row-expand-icon-expanded {
  color: #262626 !important;
  border-radius: 0px !important;
  border: 1px solid #262626 !important;
  opacity: 1 !important;
  background: transparent !important;
}

/* Nested table headers */
.ant-table-expanded-row table th, .ant-table-expanded-row table th::before {
  background: #fafafa !important;
}

/* Reorder table header background color */
.reorder-table table th {
  background: #fafafa !important;
}

/* Placed orders table alt row background color */
.placed-orders-table table tr:nth-child(even)::not(.ant-table-row-selected) td {
  background: #fafafa !important;
}